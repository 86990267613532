"use client";

import { SessionProvider } from "next-auth/react";
import { ReactNode } from "react";
import GoogleClientIdProvider from "@/app/context/GoogleClientIdProvider";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function Provider({
  children,
  session,
}: {
  children: ReactNode;
  session: any;
}): ReactNode {
  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <GoogleClientIdProvider>{children}</GoogleClientIdProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/context/client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Status/Status.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/alert.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/spinner.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/success.svg");

"use client";

import React, { FunctionComponent } from "react";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

type Props = {
  className: string | undefined;
  name: string;
  style?: any;
  spinningIcon?: boolean;
  icon?: React.ReactNode;
};

const Status: FunctionComponent<Props> = ({
  className,
  name,
  style,
  icon,
  spinningIcon,
}) => {
  const t = useTranslations();

  return (
    <div
      style={style}
      className={twMerge(
        "absolute top-0 right-0 z-10 flex items-center gap-[4px] px-[17px] py-[2px] text-[12px] rounded-bl-[11px] font-bold",
        className,
      )}
    >
      <div className={twMerge(spinningIcon && "animate-spin-slow")}>{icon}</div>
      {t(name)}
    </div>
  );
};

export default Status;

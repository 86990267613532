"use client";

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type DefaultProps = {
  googleClientId: string;
};

const defaultValue: DefaultProps = {
  googleClientId: "",
};

const GoogleClientIdContext = createContext(defaultValue);

export const useGoogleClientIdContext = () => useContext(GoogleClientIdContext);

export const GoogleClientIdProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [googleClientId, setGoogleClientId] = useState("");

  useEffect(() => {
    window.gtag &&
      window.gtag("get", "G-TWQZEBEGZX", "client_id", (field: string) =>
        setGoogleClientId(field),
      );
  }, []);

  return (
    <GoogleClientIdContext.Provider
      value={{
        googleClientId,
      }}
    >
      {children}
    </GoogleClientIdContext.Provider>
  );
};

export default GoogleClientIdProvider;
